'use strict';

$(function() {
	function setup() {
        Fancybox.bind("[data-fancybox]", {
            contentClick: "toggleZoom",
            'on': {
                'close': (event, slide) => {
                    $('#fancybox-content-description-text').remove();
                    $('.fancybox-content-description').removeClass()
                },
            },
            Images: {
                content: (_ref, slide) => {
                    const imageAlt = slide.productTitle || ''; // Use product title as alt text if available
                    // if (slide.video){
                    //   const contentHtml = `
                    //       <video class="min-w-[200px] max-w-[300px] sm:min-w-[400px] sm:max-w-[500px] sm:max-w-[400px]" src="${slide.video}" autoplay muted loop></video>
                    //   `
                    //   return contentHtml;
                    // }
                    const contentHtml = `
                        <picture class='fancybox-content-description'>
                            <img class="min-w-[200px] sm:min-w-[400px] max-w-[300px] sm:max-w-[400px]" style="flex: 7;" src="${slide.src}" alt="${imageAlt}" />
                            <div style="flex: 3;" id='fancybox-content-description-text' class="w-full items-center">
                                <div class="text-center w-full">
                                    <div class="w-full mt-[30%] p-[0.5rem]">
                                        <p class="capitalize">${slide.productTitle}</p>
                                        <p class="mt-[0.5rem] sm:mt-[1.5rem] first-letter:uppercase">${slide.productDescription}</p>
                                        <p class="my-[0.5rem] sm:my-[1.5rem]">Dimension: ${slide.productDimension}</p>
                                        <p class="my-[0.5rem] sm:my-[1.5rem]">Poids: ${slide.productWeight} kg</p>
                                        <p class="my-[0.5rem] sm:my-[1.5rem]">Prix: ${slide.productPrice} &#8364;</p>
                                    </div>
                                </div>
                            </div>
                        </picture>
                    `;
                    return contentHtml;
                },
            },
          });
	}
    setup();
});
